import { Environment } from '../environments/environment';
import store from '../Store';
import { ErrorApi } from './ErrorApi';
import {LocalStorageManager} from "../services/LocalStorageManager";
const axios = require('axios').default;

export class OrderApi {
    static async getStripeToken(args) {
        const jwt = LocalStorageManager.getJWT();
        let url = OrderApi.StripeTokenRoute(args);

        if (null !== jwt) {
            url += '?jwt=' + jwt;
        }

        try {
            const response = await axios.get(
                url, {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: OrderApi.StripeTokenRoute(args),
                status: err.response.status,
                response: err.message,
                data: args,
                method: 'GET'
            });
        }
    }

    static StripeTokenRoute(args) {
        return Environment.get().API_URL
            .concat('order/')
            .concat(Environment.get().PAYMENT_PROCESSOR)
            .concat('/')
            .concat(args);
    }
}
