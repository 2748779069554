import { TIPS_CHANGE } from '../actions/TipsActions';

const InitialState = {
    tips: 0
};

export function TipsReducer(state = InitialState, action) {
    if (action.type === TIPS_CHANGE) {
        return {
            ...state,
            tips: action.tips
        };
    }

    return state;
}
