import {Environment} from '../environments/environment';
import store from '../Store';
import {ErrorApi} from './ErrorApi';
import {NoteUrl} from "../app/NoteUrl/NoteUrl";

const axios = require('axios').default;

export class LoginApi {

    static async autoLogin() {
        try {
            const response = await axios.get(
                LoginApi.autoLoginRoute()
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: Environment.get().API_URL.concat('autologin/'),
                status: err.response.status,
                response: err.message,
                data: null,
                method: 'GET'
            });
            throw err;
        }
    }

    static async jwtLogin(jwt) {
        try {
            const noteUrl = new NoteUrl();

            const response = await axios.get(
                LoginApi.jwtLoginRoute() + '?jwt=' + jwt + '&uuid=' + noteUrl.restaurantUuid
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: LoginApi.jwtLoginRoute(),
                status: err.response.status,
                response: err.message,
                data: null,
                method: 'GET'
            });
            throw err;
        }
    }

    static async aeo(data) {
        const response = await axios.post(Environment.get().API_URL.concat('note/aeo'), data);

        if (response) {
            return response.data;
        }

        return {};
    }

    static async isAeo(data) {
        const response = await axios.post(Environment.get().API_URL.concat('note/isaeo'), data);

        if (response) {
            return response.data;
        }

        return {};
    }

    static async logout() {
        try {
            const response = await axios.get(
                Environment.get().API_URL.concat('note/session/').concat(store.getState().session.session.id).concat('/logout'), {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: Environment.get().API_URL.concat('note/session/').concat(store.getState().session.session.id).concat('/logout'),
                status: err.response.status,
                response: err.message,
                data: store.getState().session.session.id,
                method: 'GET'
            });

            throw err;
        }
    }

    static autoLoginRoute() {
        return Environment.get().API_URL.concat('autologin/');
    }

    static jwtLoginRoute() {
        return Environment.get().API_URL.concat('lyfLogin/');
    }

    static async loginCheck(_login, _password) {
        try {
            let username = _login;
            let password = _password;

            const response = await axios.post(
                LoginApi.LoginCheckRoute(), {
                    username,
                    password
                }, {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: Environment.get().API_URL.concat('autologin/'),
                status: err.response.status,
                response: err.message,
                data: null,
                method: 'GET'
            });
        }
    }

    static LoginCheckRoute() {
        return Environment.get().API_URL.concat('login_check');
    }
}
