import { Environment } from '../environments/environment';
import { LocalStorageManager } from '../services/LocalStorageManager';
import { TokenManager } from '../services/TokenManager';
const axios = require('axios').default;

export class ContextApi {

    static async getContext(token, sessionId) {
        try {

            let authtoken = LocalStorageManager.read('token');
            TokenManager.dispatch(authtoken);

            const response = await axios.get(
                ContextApi.contextRoute(token, sessionId),
                {
                    headers: {
                        'Authorization': "Bearer ".concat(authtoken)
                    }
                }
            );
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    static contextRoute(token, sessionId) {
        return Environment.get().API_URL
            .concat('session/')
            .concat(sessionId)
            .concat('/payment_note/')
            .concat(token);
    }

}