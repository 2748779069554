import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import { TipsHeader } from "../components/TipsHeader/TipsHeader";
import store from '../Store';
import TextField from '@material-ui/core/TextField';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STEP_CHANGE } from '../actions/stepActions';
import Button from '@material-ui/core/Button';
import { TIPS_CHANGE } from '../actions/TipsActions';
import { LocalStorageManager } from '../services/LocalStorageManager';
import { LOADER_VISIBLE } from '../components/Loader/actions/actions';
import { PaymentNoteApi } from '../api/PaymentNoteApi';
import { OrderApi } from '../api/OrderApi';
import { Environment } from '../environments/environment';
import { AMOUNT_TO_PAY } from '../actions/AmountToPay';

export class TipView extends React.Component {
    cashbackable = false;

    constructor(props) {
        super(props);

        this.state = {
            visible: store.getState().steps.step === 2,
            value: 'NA'
        };

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 2 });
        });
    }

    goTo(url) {
        setTimeout(
            function() {
                document.location.href = url;
            }, 250
        );
    }

    goToP(url) {
        setTimeout(
            function() {
                document.location.href = Environment.get().API_BASE + 'go.php?l=' + btoa(url);
            }, 250
        );
    }

    deviceOS() {
        const useragent = navigator.userAgent;

        if (useragent.match(/Android/i)) {
            return 'android';
        } else if (useragent.match(/webOS/i)) {
            return 'webos';
        } else if (useragent.match(/iPhone/i)) {
            return 'ios';
        } else if (useragent.match(/iPod/i)) {
            return 'ios';
        } else if (useragent.match(/iPad/i)) {
            return 'ios';
        } else if (useragent.match(/Windows Phone/i)) {
            return 'windows phone';
        } else if (useragent.match(/SymbianOS/i)) {
            return 'symbian';
        } else if (useragent.match(/RIM/i) || useragent.match(/BB/i)) {
            return 'blackberry';
        }

        return false;
    }

    handleTips() {
        const dataStore = new LocalStorageManager('app');
        const jwt = LocalStorageManager.getJWT();
        let tips = this.state.value;
        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

        const val = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);

        const user = dataStore.get('USER', JSON.parse('{}'));

        if (user.aeoUserId !== undefined && user.cashbacks !== undefined) {
            const valCashback = parseFloat('' + user.cashbacks[0].amount);
            this.cashbackable = user.aeoUserId !== null && val > 0 && valCashback > 0;
        }

        if (tips === '' || tips === null) {
            tips = 0;
        }

        store.dispatch({ type: TIPS_CHANGE, tips });
        let step = 0;

        if (!this.cashbackable && (!LocalStorageManager.read('user').isGuest || null !== jwt)) {
            step = 0;
            store.dispatch({ type: STEP_CHANGE, step });
            store.dispatch({ type: LOADER_VISIBLE });
            const stripe = this.props.stripe;

            PaymentNoteApi.create().then((response) => {
                OrderApi.getStripeToken(response.id).then((responseToken) => {
                    if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                        stripe.redirectToCheckout({ sessionId: responseToken }).then((log) => {
                            console.log(log);
                        });
                    } else {
                        if (null === jwt) {
                            window.location.href = responseToken.redirect;
                        } else {
                            this.goTo(responseToken.redirect);
                        }
                    }
                });
            });
        } else if(this.cashbackable && !LocalStorageManager.read('user').isGuest) {
            step = 'CASHBACK';
            store.dispatch({ type: STEP_CHANGE, step });
        } else {
            const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));
            step = percent > 0 ? 3 : 5;

            store.dispatch({ type: STEP_CHANGE, step });
        }
    }

    handleNoTips() {
        const dataStore = new LocalStorageManager('app');
        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));
        const step = percent > 0 ? 3 : 5;
        store.dispatch({ type: STEP_CHANGE, step });
    }

    handleBlur(event) {
        let value = event.target.value;

        if (value.length === 0) {
            value = 0;

            this.setState({ value });
        }
    }

    handleFocus(event) {
        let value = event.target.value;

        if (value.length === 1) {
            value = parseInt(value, 10);

            if (value === 0) {
                value = '';

                this.setState({ value });
            }
        }
    }

    handleChange(event) {
        let value = event.target.value;

        if (value.length === 0) {
            value = 0;
        }

        if (value >= 0) {
            let regex = /^\d+(?:\.\d{1,2})?$/;

            if (!regex.test(value) && value !== ''){
                value = Number(value).toFixed(2);
            }

            this.setState({ value });
        }
    }

    increment() {
        if (this.state.value === 'NA') {
            this.setState({ value: 1});
        } else {
            this.setState({ value: Number(this.state.value) + 1 });
        }
    }

    decrement() {
        if (this.state.value !== 'NA') {
            if (this.state.value >= 1) {
                this.setState({ value: this.state.value - 1 });
            }
        }
    }

    handleBack() {
        let step;

        if (store.getState().amountToPay.action && store.getState().amountToPay.action === 'TOTAL') {
            step = 1;
        } else {
            step = 'SPLIT_AMOUNT';
        }

        store.dispatch({ type: STEP_CHANGE, step });
        store.dispatch({ type: AMOUNT_TO_PAY, amountToPay: 0, action: 'TOTAL' });
    }


    render() {
        const dataStore = new LocalStorageManager('app');
        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

        const val = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);
        const classCss = val > 0 ? 'cagnotte center' : 'cagnotte center noShow';

        return (
            <div className={ this.state.visible ? '' : 'none' }>
                <TipsHeader></TipsHeader>

                <div className="center tips-input">
                    <FontAwesomeIcon icon={faMinus} className="fa-2x tips-icon" onClick={this.decrement.bind(this)}/>
                    <TextField
                        id="outlined-basic"
                        label="Pourboire"
                        variant="outlined"
                        type="number"
                        value={ this.state.value === 'NA' ? 0 : this.state.value }
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // inputProps={{ inputMode: 'decimal', min: 0 }}
                        onChange={this.handleChange.bind(this) }
                        onFocus={this.handleFocus.bind(this) }
                        onBlur={this.handleBlur.bind(this) }
                    />
                    <FontAwesomeIcon icon={faPlus} className="fa-2x tips-icon" onClick={this.increment.bind(this)}/>
                </div>

                <div className={ classCss }>
                    <strong>Cagnottez { val } €</strong> sur cette addition
                </div>

                <div className="button-container">
                    <Button onClick={this.handleTips.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Continuer
                    </Button>
                </div>
                <div className="button-container b-sec">
                    <Button onClick={this.handleBack.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        retour
                    </Button>
                </div>
            </div>
        );
    }
}
