import { AMOUNT_TO_PAY } from '../actions/AmountToPay';

const InitialState = {
    amountToPay: 0
};

export function AmountToPayReducer (state = InitialState, action) {
    if (action.type === AMOUNT_TO_PAY && action.action === 'TOTAL' ) {
        console.log(action.amountToPay);
        return {
            ...state,
            amountToPay: action.amountToPay,
            action: action.action
        }
    } else if(action.type === AMOUNT_TO_PAY && action.action === 'ADD_PRODUCT'){
        /*console.log('ADD_PRODUCT');
        console.log(action.amount);
        console.log(state.amountToPay);
        console.log(action.amount + state.amountToPay);*/
        return {
            ...state,
            amountToPay: action.amount + state.amountToPay,
            action: action.action
        }
    } else if(action.type === AMOUNT_TO_PAY && action.action === 'REMOVE_PRODUCT'){
        /*console.log('REMOVE_PRODUCT');
        console.log(action);
        console.log(action.amount);
        console.log(state.amountToPay);
        console.log(state.amountToPay - action.amount);*/
        let am = state.amountToPay - action.amount >= 0 ? state.amountToPay - action.amount: 0;

        return {
            ...state,
            amountToPay: am,
            action: action.action
        }
    }

    return state;
}
