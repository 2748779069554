import React from "react";
import store from '../Store';

export class Snack extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            vertical: 'top',
            horizontal: 'center',
            message: ''
        };

        store.subscribe(() => {
            
            let msg = store.getState().message.message;
           if (msg && msg !== this.state.message) {
                this.setState({ message: msg.message});
                this.setState({open: true});
            }
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div className={this.state.open ? 'snack' : 'snack none'}>
                {this.state.message}
            </div>
        )
    }
}
