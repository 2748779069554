import React from "react";
import { Environment } from '../../environments/environment';

export class LogoOnePay extends React.Component{
    render() {
        let logo;

        if (Environment.get().DESIGN === 'onepay') {
            logo = 'logo.svg';
        } else {
            logo = 'logo.png';
        }

        return (<img src={process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/' + logo} className="loader-logo" alt="OnePay logo" />)
    }
}
