import { MESSAGE_NEW } from '../actions/MessageActions';

const InitialState = {
    message: null
};

export function MessageReducer(state = InitialState, action) {


    if (action.type === MESSAGE_NEW) {


        return {
            ...state,
            message: action.message
        }
    }

    return state;
}