import { STEP_CHANGE } from '../actions/stepActions';

const InitialState = {
    step: 0
};

export function stepReducer(state = InitialState, action) {
    if (action.type === STEP_CHANGE) {
        return {
            ...state,
            step: action.step
        };
    }

    return state;
}
