import React from "react";
import store from '../Store';

export class SplashView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: store.getState().steps.step === 'SPLASH'
        };

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 'SPLASH' });
        });
    }

    render() {
        let logo;

        if (process.env.REACT_APP_ENVIRONMENT === 'lyfpay') {
            logo = 'logo.png';
        } else {
            logo = 'logo.svg';
        }

        return (
            <div className={this.state.visible ? 'center' : 'center none'}>
                <img src={process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/' + logo} className="loader-logo" alt="logo" />
                <div className="cagnotte center">
                    Scannez le QR Code de votre addition pour la payer ou la partager et soyez recompens&eacute; !
                </div>
                <img src={process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/splash.png'} className="splashscreen" alt={process.env.REACT_APP_ENVIRONMENT} />
            </div>
        );
    }
}
