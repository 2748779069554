export class User {
    id;
    firstname;
    lastname;
    email;
    isGuest;
    phone;
    isAcked;
    password;

    constructor() {
        this.id = null;
        this.firstname = null;
        this.lastname = null;
        this.email = null;
        this.isGuest = null;
        this.phone = null;
        this.isAcked = null;
        this.password = null;
    }

    importUser(data) {
        if (data) {
            this.id = data.id;
            if (data.firstname) {
                this.firstname = data.firstname;
            }
            if (data.firstName) {
                this.firstname = data.firstName;
            }
            if (data.lastname) {
                this.lastname = data.lastname;
            }
            if (data.lastName) {
                this.lastname = data.lastname;
            }
            this.email = data.email;
            this.isGuest = data.isGuest;
            this.phone = data.phone;
            this.isAcked = data.isAcked;
            this.password = data.password
        }
    }
    
}
