import React from "react";
import store from '../Store';
import Button from '@material-ui/core/Button';
import { ContextApi } from '../api/ContextApi';
import { Note } from '../app/Note/Note';
import { NOTE_CHANGE } from '../actions/NoteActions';
import { PointOfSales } from '../app/PointOfSales';
import { POINT_OF_SALES_CHANGE } from '../actions/PointOfSalesActions';
import { SESSION_CHANGE } from '../actions/SessionActions';
import { NoteApi } from '../api/NoteUrl/NoteApi';
import { ErrorApi } from '../api/ErrorApi';
import { Environment } from '../environments/environment';
import { CHANNEL } from '../actions/ChannelActions';

export class CancelView extends React.Component {

    constructor(props) {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        super(props);

        this.state = {
            visible: false,
            session: urlParams.get('session'),
            context: urlParams.get('context'),
            amount: urlParams.get('paidAmount') || 0,
            date: urlParams.get('date'),
            tip: urlParams.get('tipAmount'),
            restaurantUUID: null,
            noteId: null
        }



        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 6 });
        });

        if (window.location.href.includes('cancel')) {
            ContextApi.getContext(urlParams.get('context'), urlParams.get('session')).then((response) => {
                if (response) {
                    this.setState({ restaurantUUID: response.restaurantUUID, noteId: response.noteNumber })
                    NoteApi.getNote(response.restaurantUUID, response.noteNumber)
                        .catch(err => {
                            ErrorApi.Log({
                                request: Environment.get().API_URL.concat('getNote/'),
                                status: err.response.status,
                                response: err.message,
                                data: null
                            });
                        }).then(data => {
                            if (data) {
                                this.setState({ source: data.source });
                                let channel = data.session.channel;
                                store.dispatch({ type: CHANNEL, channel })
                                let note = new Note();
                                note.importNote(data);
                                store.dispatch({ type: NOTE_CHANGE, note });

                                let pointOfSales = new PointOfSales();
                                pointOfSales.importPointOfSales(data.store);
                                store.dispatch({ type: POINT_OF_SALES_CHANGE, pointOfSales });

                                let session = data.session;
                                store.dispatch({ type: SESSION_CHANGE, session });
                            }
                        })
                }
            })
        }


    }

    handleLogin() {
        window.location.href = process.env.PUBLIC_URL.concat('note?box_id=').concat(this.state.restaurantUUID).concat('&note_id=').concat(this.state.noteId);
    }

    render() {
        return (
            <div className={store.getState().steps.step === 7 ? 'cancel' : 'cancel none'}>
                <div className='header-cancel'>
                    <h1>Paiement Annul&eacute;</h1>
                    <div className="header-cancel-total">{(Number(this.state.amount) + Number(this.state.tip)).toFixed(2)} &euro;</div>
                    <p>{this.state.date}</p>
                </div>
                <br/>
                <div>
                    <p className="text">La transaction n'a pas &eacute;t&eacute; effectu&eacute;e, votre carte bancaire n'a pas &eacute;t&eacute; d&eacute;bit&eacute;e</p>
                </div>
                <br />
                <div className="button-container b-sec">
                    <Button onClick={this.handleLogin.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Revenir au ticket
                    </Button>
                </div>
            </div>
        );
    }
}
