import React from "react";
import store from '../Store';
import Button from '@material-ui/core/Button';
import { ContextApi } from '../api/ContextApi';
import { NoteApi } from '../api/NoteUrl/NoteApi';
import { NOTE_CHANGE } from '../actions/NoteActions';
import { PointOfSales } from '../app/PointOfSales';
import { POINT_OF_SALES_CHANGE } from '../actions/PointOfSalesActions';
import { SESSION_CHANGE } from '../actions/SessionActions';
import { Note } from '../app/Note/Note';
import { ErrorApi } from '../api/ErrorApi';
import { CHANNEL } from '../actions/ChannelActions';
import {LocalStorageManager} from "../services/LocalStorageManager";

export class SuccessView extends React.Component {
    constructor(props) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        super(props);

        this.state = {
            visible: false,
            session: urlParams.get('session'),
            context: urlParams.get('context'),
            amount: urlParams.get('paidAmount') || 0,
            date: urlParams.get('date'),
            tip: urlParams.get('tipAmount'),
            locked: urlParams.get('locked'),
            table: urlParams.get('tableRef'),
            source: null,
            restaurantUUID: null,
            noteId: null
        };

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 6 });
        });

        if (window.location.href.includes('success')) {
            ContextApi.getContext(urlParams.get('context'), urlParams.get('session')).then((response) => {
                if (response) {
                    this.setState({ restaurantUUID: response.restaurantUUID, noteId: response.noteNumber  });

                    NoteApi.getNote(response.restaurantUUID, response.noteNumber)
                        .catch(err => {
                            ErrorApi.Log({
                                request: 'getNote',
                                status: err.response.status,
                                response: err.message,
                                data: null
                            });
                        }).then(data => {
                            if (data) {
                                this.setState({ source: data.source });

                                let channel = data.session.channel;
                                store.dispatch({ type: CHANNEL, channel });

                                let note = new Note();
                                note.importNote(data);
                                store.dispatch({ type: NOTE_CHANGE, note });

                                let pointOfSales = new PointOfSales();
                                pointOfSales.importPointOfSales(data.store);
                                store.dispatch({ type: POINT_OF_SALES_CHANGE, pointOfSales });

                                let session = data.session;
                                store.dispatch({ type: SESSION_CHANGE, session });
                            }
                        });
                }
            });
        }
    }

    handleLogin() {
        window.location.replace(
            process.env.PUBLIC_URL .
            concat('note/?box_id=' + this.state.restaurantUUID + '&note_id=' + this.state.noteId)
        );
    }

    render() {
        let txt = "";
        let table = "";
        const dataStore = new LocalStorageManager('app');
        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

        const val = ((Number(this.state.amount)) * percent / 100).toFixed(2);

        let cashbackable = false;
        let cashed = 0;

        const user = dataStore.get('USER', JSON.parse('{}'));

        if (user.aeoUserId !== undefined && percent > 0) {
            cashed = ((Number(this.state.amount)) * percent / 100).toFixed(2);
            cashbackable = user.aeoUserId !== null && val > 0 && cashed > 0;
        }

        if (cashbackable) {
            txt = <p className="text">Vous avez reçu <strong>{cashed} €</strong> à utiliser lors de votre prochaine commande</p>;
        }

        if (this.state.table != null && this.state.table.length > 0) {
            table = <p>Table <strong>{this.state.table}</strong></p>;
        }

        let thxTxt = 'Merci de montrer cette confirmation de paiement en sortant';

        if (!this.state.locked){
            thxTxt = 'Merci pour votre règlement. Une fois le montant total payé, la dernière personne ayant effectué un paiement devra présenter la confirmation en sortant'
        }

        return (
            <div className={store.getState().steps.step === 6 ? 'success' : 'success none'}>
                <div className={this.state.locked === 1 ? 'header-success green' : 'header-success orange'}>
                    <h1>Paiement effectu&eacute;</h1>
                    <div className="header-success-total">{(Number(this.state.amount) + Number(this.state.tip)).toFixed(2)} &euro;</div>
                    <br/>
                    <p>{this.state.date}</p>
                    {table}
                </div>
                <div>
                    <h2>{thxTxt}</h2>
                    {
                        txt
                    }
                </div>
                <br />
                <div className="button-container b-sec">
                    <Button onClick={this.handleLogin.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Revenir au ticket
                    </Button>
                </div>
                <br/>
                <div className="button-container center">
                    <a href={ this.state.source } target="_blank" className="link">Sauvegarder mon ticket num&eacute;rique</a>
                </div>
            </div>
        );
    }
}
