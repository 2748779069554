import React from "react";
import {LocalStorageManager} from "../services/LocalStorageManager";
import store from "../Store";
import TextField from "@material-ui/core/TextField";
import {AMOUNT_TO_PAY} from "../actions/AmountToPay";
import Button from "@material-ui/core/Button";
import {STEP_CHANGE} from "../actions/stepActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {LOADER_VISIBLE} from "../components/Loader/actions/actions";
import {PaymentNoteApi} from "../api/PaymentNoteApi";
import {OrderApi} from "../api/OrderApi";
import {Environment} from "../environments/environment";

export class CashbackView extends React.Component {
    cagnotte = 0;
    cagnotteAmount = 0;
    initialAmount = 0;

    constructor(props) {
        super(props);

        this.state = {
            visible: store.getState().steps.step === 'CASHBACK',
            cashback_amount: 'NA'
        };

        store.subscribe(() => {
            this.setState({
                visible: store.getState().steps.step === 'CASHBACK',
            });
        });
    }

    handleCagnotte(event) {
        const dataStore = new LocalStorageManager('app');
        const jwt = LocalStorageManager.getJWT();

        let cashbackAmount = this.state.cashback_amount;

        if (cashbackAmount === 'NA') {
            cashbackAmount = 0;
        }

        dataStore.set('CASHBACK_AMOUNT', cashbackAmount);

        let step = 0;

        store.dispatch({ type: STEP_CHANGE, step });
        store.dispatch({ type: LOADER_VISIBLE });

        const stripe = this.props.stripe;

        PaymentNoteApi.create().then((response) => {
            OrderApi.getStripeToken(response.id).then((responseToken) => {
                if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                    stripe.redirectToCheckout({ sessionId: responseToken }).then((log) => {
                        console.log(log);
                    });
                } else {
                    if (null === jwt) {
                        window.location.href = responseToken.redirect;
                    } else {
                        this.goTo(responseToken.redirect);
                    }
                }
            });
        });
    }

    handleBlur(event) {
        this.handleChange(event);
    }

    processChange(cashback_amount) {
        const dataStore = new LocalStorageManager('app');

        if (cashback_amount >= 0) {
            let regex = /^\d+(?:\.\d{1,2})?$/;
            let toPay = 0;

            if (this.initialAmount === 0) {
                toPay = Number(store.getState().amountToPay.amountToPay).toFixed(2);

                this.initialAmount = toPay;
            } else {
                toPay = this.initialAmount;
            }

            if (!regex.test(cashback_amount) && cashback_amount !== '') {
                cashback_amount = Number(cashback_amount).toFixed(2);
            }

            if (cashback_amount > this.cagnotte) {
                cashback_amount = this.cagnotte;
            }

            if (cashback_amount > toPay) {
                cashback_amount = toPay;
            }

            const amountToPay = toPay - cashback_amount;

            store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL' });

            const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));
            this.cagnotteAmount = ((Number(amountToPay)) * percent / 100).toFixed(2);

            if (cashback_amount === 0) {
                cashback_amount = 'NA';
            }

            this.setState({ cashback_amount });
        }
    }

    handleChange(event) {
        this.data();

        let cashback_amount = event.target.value;

        if (cashback_amount.length === 0) {
            cashback_amount = 0;
        }

        cashback_amount = parseFloat('' + cashback_amount);

        this.processChange(cashback_amount);
    }

    data() {
        const dataStore = new LocalStorageManager('app');
        const user = dataStore.get('USER', JSON.parse('{}'));

        if (user.cashbacks !== undefined) {
            this.cagnotte = parseFloat('' + user.cashbacks[0].amount);
        }

        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

        this.cagnotteAmount = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);
    }

    handleBack() {
        let step;

        if (store.getState().amountToPay.action && store.getState().amountToPay.action === 'TOTAL') {
            step = 1;
        } else {
            step = 'SPLIT_AMOUNT';
        }

        store.dispatch({ type: STEP_CHANGE, step });
        store.dispatch({ type: AMOUNT_TO_PAY, amountToPay: 0, action: 'TOTAL' });
    }

    increment() {
        this.data();

        if (this.state.cashback_amount === 'NA') {
            this.processChange(1);
        } else {
            this.processChange(Number(this.state.cashback_amount) + 1);
        }
    }

    decrement() {
        if (this.state.cashback_amount !== 'NA') {
            if (this.state.cashback_amount >= 1) {
                this.data();
                this.processChange(this.state.cashback_amount - 1);
            }
        }
    }

    handleFocus(event) {
        let cashback_amount = event.target.value;

        if (cashback_amount.length === 1) {
            cashback_amount = parseFloat('' + cashback_amount);

            if (cashback_amount === 0) {
                cashback_amount = '';

                this.setState({ cashback_amount });
            }
        }
    }

    render() {
        this.data();

        return (
            <div className={this.state.visible ? '' : 'none'}>
                <div className="center cagnotte-img">
                    <img src={process.env.PUBLIC_URL + '/wallet.png'} className="loader-logo2" alt="Cagnotte personnelle" />
                </div>

                <div className="center tips-input">
                    <strong>Cagnottez { this.cagnotteAmount } €</strong> en payant cette addition.
                    <p>
                        Vous disposez déjà de { this.cagnotte } € dans votre cagnotte.
                    </p>
                    <h1>Utiliser ma cagnotte pour</h1>
                    <div className="center tips-input">
                        <FontAwesomeIcon icon={faMinus} className="fa-2x tips-icon" onClick={this.decrement.bind(this)}/>
                        <TextField
                            id="outlined-basic"
                            label="Montant en €"
                            variant="outlined"
                            type="number"
                            value={ this.state.cashback_amount === 'NA' ? 0 : this.state.cashback_amount }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ inputMode: 'decimal', min: 0 }}
                            onChange={this.handleChange.bind(this) }
                            onBlur={this.handleBlur.bind(this) }
                            onFocus={this.handleFocus.bind(this) }
                        />
                        <FontAwesomeIcon icon={faPlus} className="fa-2x tips-icon" onClick={this.increment.bind(this)}/>
                    </div>

                    <div className="button-container">
                        <Button onClick={this.handleCagnotte.bind(this)} color="primary" variant="outlined" className="dialog-button">
                            Continuer
                        </Button>
                    </div>
                    <div className="button-container b-sec">
                        <Button onClick={this.handleBack.bind(this)} color="primary" variant="outlined" className="dialog-button">
                            retour
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
