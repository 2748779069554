import {LOADER_INVISIBLE, LOADER_VISIBLE} from '../actions/actions';

const InitialState = {
    isActive: true
};

export function loaderReducer(state = InitialState, action) {
    if(action.type === LOADER_VISIBLE){
        return {
            ...state,
            isActive: true
        }
    }

    if (action.type === LOADER_INVISIBLE) {
        return {
            ...state,
            isActive: false
        }
    }

    return state;
}