export class PointOfSales {

    id;
    name;
    slug;
    logo;

    constructor() {
        this.id = null;
        this.name = null;
        this.slug = null;
        this.logo = null;
    }

    importPointOfSales(data) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.logo = data.logo;
    }
}