import { Note } from '../app/Note/Note';
import {NOTE_CHANGE} from '../actions/NoteActions';

const InitialState = {
    note: new Note()
};

export function noteReducer(state = InitialState, action) {
    if(action.type === NOTE_CHANGE){
        return {
            ...state,
            note: action.note
        }
    }

    return state;
}