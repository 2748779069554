import React from "react";
import store from '../Store';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { STEP_CHANGE } from '../actions/stepActions';
import { AMOUNT_TO_PAY } from '../actions/AmountToPay';
import { LocalStorageManager } from '../services/LocalStorageManager';

export class SplitAmountView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			total: 0,
			visible: false,
			paymentNote: [],
			me: null,
			value: 0,
		};

		store.subscribe(() => {
			this.setState({ visible: store.getState().steps.step === 'SPLIT_AMOUNT' });
			this.setState({ total: store.getState().steps.step === 'SPLIT_AMOUNT' });
			this.setState({value: store.getState().amountToPay.amountToPay});

			if (store.getState().user.user) {
				this.setState({ me: store.getState().user.user });
			}

			let previousValue = this.state.total;
			let currentNoteTotal = store.getState().note.note.totalVATInc;

			if (!previousValue || previousValue.noteTotal !== currentNoteTotal) {
				let paidAmount = 0;

				if (store.getState().session.session) {
					paidAmount = store.getState().session.session.paidAmount;
				}

				if (currentNoteTotal && paidAmount) {
					this.setState({
						total: (currentNoteTotal - paidAmount).toFixed(2)
					});
				} else if (currentNoteTotal) {
					this.setState({
						total: (currentNoteTotal).toFixed(2)
					});
				}
			}

			if (store.getState().session.session) {
				this.setState({ paymentNote: store.getState().session.session.paymentNotes });
			}

			this.state.paymentNote.map((user, index) => {
				let firstname = 'Client '.concat(user.payer.id);

                if (user.firstname !== null) {
					firstname = user.firstname;
				}

				if (user.firstName !== null) {
					firstname = user.firstName;
				}

				if (!firstname) {
					firstname = 'Client '.concat(user.payer.id);
				}

				if (LocalStorageManager.read('user') && user.id === LocalStorageManager.read('user').id) {
					firstname = 'Moi';
				}

				user.firstname = firstname;
			});
		});
	}

	handleBlur(event) {
        let value = event.target.value;

        if (value.length === 0) {
            value = 0;

            this.setState({ value });
        }
    }

	handleFocus(event) {
        let value = event.target.value;

        if (value.length === 1) {
            value = parseInt(value, 10);

            if (value === 0) {
                value = '';

                this.setState({ value });
            }
        }
    }

	handleChange(event) {
		let value;
		value = event.target.value;

		if (value < 0) {
			value = 0;
		} else {
			let regex = /^\d+(?:\.\d{1,2})?$/;

			if (!regex.test(value) && value !== null && value != ''){
				value = Number(value).toFixed(2);
			}
		}

        this.setState({value: value});
	}

	handleTips() {
		let step = 2;
		store.dispatch({ type: STEP_CHANGE, step });
		let amountToPay = this.state.value;

        if (!amountToPay) {
			amountToPay = 0;
		}

		amountToPay = Number(amountToPay);
		store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL' });
	}

	handleClick(event) {
		if (event.target.value === '0') {
			this.setState({ value: null });
        }
	}

	handleBack() {
		let step = 1;
		store.dispatch({ type: STEP_CHANGE, step });
		store.dispatch({ type: AMOUNT_TO_PAY, amountToPay: 0, action: 'TOTAL' });
    }

	render() {
        const notes = this.state.paymentNote ? this.state.paymentNote : [];
        const grouped = {};
        const all = [];

        notes.map((note, index) => {
            if (note.status === 'PAYED') {
                const key = note.payer.email;

                if (typeof grouped[key] === 'undefined') {
                    all.push(key);
                    grouped[key] = {
                        'name': note.payer.firstName,
                        'avatar': note.firstname === 'Moi' ? process.env.PUBLIC_URL + '/moi.png' : process.env.PUBLIC_URL + '/avatar.png',
                        total: 0
                    };
                }

                grouped[key]['total'] += note.amount;
                grouped[key]['total'] = Math.round(grouped[key]['total'] * 100) / 100;
            }
        });

		return (
			<div className={this.state.visible ? '' : 'none'}>
				<div className="total-header">
					<h1>Partager l'addition</h1>
					<p>Montant restant</p>
					<div className="total-header-total">{this.state.total} &euro;</div>
				</div>
				<div className="users-list-me">
					{
                        all.length ? all.map((k, index) => {
                            const n = grouped[k];

							return <div className="user"><img src={n.avatar} className="user-img" alt="OnePay logo"/><br />{n.name}<br/> {n.total} &euro; </div>
						}) : null
					}
				</div>

				<div className="center amount-split-input">
					<div className="user-me">
						<img src={process.env.PUBLIC_URL + '/avatar.png'} className="user-img" alt="User avatar" />
					</div>
					<TextField
						id="outlined-basic"
						label="Montant"
						variant="outlined"
						type="number"
						value={this.state.value}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{ inputMode: 'decimal', step:1 }}
						onChange={this.handleChange.bind(this)}
						onFocus={this.handleFocus.bind(this)}
						onBlur={this.handleBlur.bind(this)}
						onClick={this.handleClick.bind(this)}
					/>
				</div>
				<br/>
				<div className="button-container">
					<Button onClick={this.handleTips.bind(this)} color="primary" variant="outlined" className="dialog-button">
						Continuer
                    </Button>
				</div>
				<div className="button-container b-sec">
					<Button onClick={this.handleBack.bind(this)} color="primary" variant="outlined" className="dialog-button">
						retour
                    </Button>
				</div>
			</div>
			);
	}
}
