import { TOKEN_GET } from '../actions/TokenActions'
import { LocalStorageManager } from '../services/LocalStorageManager';

const InitialState = {
    token: null
}

export function TokenReducer(state = InitialState, action) {
    if (action.type === TOKEN_GET) {
        LocalStorageManager.record('token', action.token);
        LocalStorageManager.record('token_date', new Date().toISOString());
        return {
            ...state,
            token: action.token
        }
    }
    return state;
}