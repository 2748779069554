import { USER_GET } from '../actions/UserActions';
import { UserApi } from '../api/UserApi';
import store from '../Store';

export class UserManager {
    static get(_token) {
        let token = _token;
        UserApi.getUser({ token }).then((userData) => {
            UserManager.dispatch(userData);
        }).catch(err => {
            console.log('there was an error');
            console.log(err);
        });
    }

    static dispatch(_userData) {
        let user = _userData;
        store.dispatch({ type: USER_GET, user });
    }

}