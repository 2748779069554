import React from "react";
import store from '../Store';

export class ErrorView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            visible: store.getState().steps.step === 'ERROR'
        }

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 'ERROR' });
        });


    }

    render() {
        let logo;
        if (process.env.REACT_APP_ENVIRONMENT === 'lyfpay') {
            logo = 'logo.png'
        } else {
            logo = 'logo.svg'
        }

        return (
            <div className={this.state.visible ? 'cancel' : 'cancel none'}>
                <div className="header-cancel">
                    <h1>Il y a eu une erreur</h1>
                    <div className="header-cancel-total">Woops...</div>
                </div>
                <br />
                <div className="center"><img src={process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/' + logo} className="loader-logo" alt="OnePay logo" /></div>
                <br/>
                <div>
                    <p className="text">Il y a eu une erreur durant le chargement de l'app, veuillez re&eacute;ssayer plus tard.</p>
                </div>
                <br />
            </div>
        );
    }
}