import React, {useEffect} from 'react';
import './App.css';
import {NoteUrl} from './app/NoteUrl/NoteUrl';
import {Loader} from './components/Loader/Loader';
import store from './Store';
import './style/main.css';
import {NoteView} from './views/NoteView';
import {LoginApi} from './api/LoginApi';
import {NOTE_CHANGE} from './actions/NoteActions';
import {NoteApi} from './api/NoteUrl/NoteApi';
import {LOADER_INVISIBLE, LOADER_VISIBLE} from './components/Loader/actions/actions';
import {STEP_CHANGE} from './actions/stepActions';
import {Note} from './app/Note/Note';
import {PointOfSales} from './app/PointOfSales';
import {POINT_OF_SALES_CHANGE} from './actions/PointOfSalesActions';
import {SESSION_CHANGE} from './actions/SessionActions';
import PubNub from 'pubnub';
import {PubNubProvider} from 'pubnub-react';
import {TokenManager} from './services/TokenManager';
import {UserManager} from './services/UserManager';
import {TipView} from './views/TipView';
import {LoginView} from './views/LoginView';
import {CagnotteView} from './views/CagnotteView';
import {RegisterView} from './views/RegisterView';
import {SplashView} from './views/SplashView';
import {CancelView} from './views/CancelView';
import {ElementsConsumer, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {LocalStorageManager} from './services/LocalStorageManager';
import {CHANNEL} from './actions/ChannelActions';
import {SuccessView} from './views/SuccessView';
import {Snack} from './components/Snack';
import {SplitAmountView} from './views/SplitAmountView';
import {ErrorApi} from './api/ErrorApi';
import {Environment} from './environments/environment';
import {ErrorView} from './views/ErrorView';
import {UserApi} from './api/UserApi';
import {USER_GET} from './actions/UserActions';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import {TIPS_CHANGE} from "./actions/TipsActions";
import {PaymentNoteApi} from "./api/PaymentNoteApi";
import {OrderApi} from "./api/OrderApi";
import {AMOUNT_TO_PAY} from "./actions/AmountToPay";
import {CashbackView} from "./views/CashbackView";

store.subscribe(() => {
    console.log('process.env.REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
    console.log(store.getState());
});

const pubnub = new PubNub({
    publishKey: 'pub-c-7550f80e-c5b5-45c3-9390-a2ca1d52263e',
    subscribeKey: 'sub-c-2869c3c0-b484-11eb-b2e5-0e040bede276',
    uuid: 'ONEPAYUUID'
});

const channels = [];

const dataStore = new LocalStorageManager('app');

store.subscribe(() => {
    if (channels.length === 0 && store.getState().channel.channel) {
        channels[0] = store.getState().channel.channel;
        pubnub.addListener({message: handleMessage});
        pubnub.subscribe({channels});
    }
});

const handleMessage = event => {
    const message = event.message;

    if (typeof message === 'string' || message.hasOwnProperty('text')) {
        let session = message.text;

        if (Array.isArray(session.users)) {
            store.dispatch({type: SESSION_CHANGE, session});
        } else {
            let users = [];

            Object.keys(session.users).forEach(key => {
                users.push(session.users[key]);
            });

            session.users = users;
        }

        if (session.isLocked) {
            let step = 1;

            store.dispatch({type: STEP_CHANGE, step});
        }
    }
};

const href = window.location.href;
const url = new URL(href);

if (href.includes('force')) {
    if (href.match('=')) {
        const hrefParts = href.split('&');
        hrefParts.pop();
        window.location.href = hrefParts.join('&');
    } else {
        const hrefParts = href.split('/');
        hrefParts.pop();
        window.location.href = hrefParts.join('/');
    }
}

if (href.includes('success')) {
    const isAeo = parseInt(url.searchParams.get('isAeo'), 10);
    const host = url.searchParams.get('host');

    if (isAeo === 1) {
        const context = url.searchParams.get('context');
        goTo(atob(host) + '/cart?context=' + context);
        // window.top.postMessage({from: 'onepay', status: 'success'}, atob(host));
    } else {
        let step = 6;
        store.dispatch({type: STEP_CHANGE, step});
        store.dispatch({type: LOADER_INVISIBLE});
    }
} else if (href.match('aeo')) {
    const cart_id = parseInt(url.searchParams.get('cart_id'), 10);
    const amount = parseFloat(url.searchParams.get('amount'));
    const slug = url.searchParams.get('slug');
    const first_name = url.searchParams.get('first_name');
    const last_name = url.searchParams.get('last_name');
    const phone = url.searchParams.get('phone');
    const email = url.searchParams.get('email');
    const user_id = url.searchParams.get('user_id');
    const host = url.searchParams.get('host');

    const data = {
        cart_id,
        amount,
        slug,
        first_name,
        last_name,
        phone,
        email,
        user_id,
        host,
    };

    LoginApi.aeo(data).catch(err => {
	console.log('aeo_error', err);
        ErrorApi.Log({
            request: LoginApi.jwtLoginRoute(),
            status: err.response.status,
            response: err.message,
            data: null
        });
    }).then((data) => {
        if (data && data.token) {
            if (data.uuid) {
                goTo('https://app.lyf.eu/shops/' + data.shop_id + '/features/pay_at_table?box_id=' + data.uuid + '&note_id=' + data.note_id);
            } else {
                document.location.href =
                    process.env.PUBLIC_URL .
                    concat('note/?pay=1&box_id=000&note_id=' + data.note_id)
                ;
            }
        } else {
            let step = 'ERROR';
            store.dispatch({type: LOADER_INVISIBLE});
            store.dispatch({type: STEP_CHANGE, step});
        }
    });
} else if (href.includes('cancel')) {
    const isAeo = parseInt(url.searchParams.get('isAeo'), 10);
    const host = url.searchParams.get('host');

    if (isAeo === 1) {
        const context = url.searchParams.get('context');
        goTo(atob(host) + '/cart?context=' + context);
        // window.top.postMessage({from: 'onepay', status: 'fail'}, atob(host));
    } else {
        let step = 7;
        store.dispatch({type: STEP_CHANGE, step});
        store.dispatch({type: LOADER_INVISIBLE});
    }
} else if (!href.includes('note')) {
    let step = 'SPLASH';
    store.dispatch({type: STEP_CHANGE, step});
    store.dispatch({type: LOADER_INVISIBLE});
} else {
    let storedUser = LocalStorageManager.read('user');
    let token = LocalStorageManager.read('token');

    const jwt = url.searchParams.get('jwt');
    let noteId = url.searchParams.get('note_id');
    let boxId = url.searchParams.get('box_id');
    const pay = url.searchParams.get('pay');

    if (boxId === null) {
        const partsHref = href.split('/');
        noteId = partsHref.pop();
        boxId = partsHref.pop();
    }

    cashback(boxId);

    if (null === noteId || (null === boxId && pay === null)) {
        store.dispatch({type: STEP_CHANGE, step: 'SPLASH'});
        store.dispatch({type: LOADER_INVISIBLE});
    } else {
        LoginApi.isAeo({note_id:noteId, 'box_id':boxId}).then((isAeo) => {
            dataStore.set('isAeo', isAeo.status);

            if (isAeo.status || pay) {
                TokenManager.dispatch(token);

                getUser(token);

                // get the user
                UserManager.get(token);

                const noteUrl = new NoteUrl();

                NoteApi.getNote(boxId, noteId).then(Notedata => {
                    if (Notedata) {
                        const amountToPay = Notedata.totalVATInc;
                        store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL' });
                        let note = new Note();
                        note.importNote(Notedata);
                        store.dispatch({ type: NOTE_CHANGE, note });

                        let pointOfSales = new PointOfSales();
                        pointOfSales.importPointOfSales(Notedata.store);
                        store.dispatch({ type: POINT_OF_SALES_CHANGE, pointOfSales });

                        let session = Notedata.session;
                        store.dispatch({ type: SESSION_CHANGE, session });
                        const tips = 0;
                        store.dispatch({ type: TIPS_CHANGE, tips });
                        let step = 0;
                        store.dispatch({ type: STEP_CHANGE, step });
                        store.dispatch({ type: LOADER_VISIBLE });

                        PaymentNoteApi.create().then((response) => {
                            OrderApi.getStripeToken(response.id).then((responseToken) => {
                                goTo(responseToken.redirect);
                            });
                        });
                    }
                }).catch(err => {
                    let step = 'ERROR';
                    store.dispatch({type: LOADER_INVISIBLE});
                    store.dispatch({type: STEP_CHANGE, step});
                });
            } else {
                const lyf_id = isAeo.lyf_id;

                if (lyf_id !== null && null === jwt) {
                    //goTo('https://app.lyf.eu/shops/' + lyf_id + '/features/pay_at_table?box_id=' + boxId +
                    // '&note_id=' + noteId);
                }

                checkIsLyf(url);

                if (!storedUser || !token) {
                    if (null !== jwt) {
                        dataStore.set('isLyf', true);
                        JWTLog(jwt);
                    } else {
                        // get the token
                        AutoLog();
                    }
                } else {
                    if (null !== jwt) {
                        dataStore.set('isLyf', true);
                        JWTLog(jwt);
                    } else {
                        if (pay) {
                            TokenManager.dispatch(token);

                            getUser(token);

                            // get the user
                            UserManager.get(token);

                            const noteUrl = new NoteUrl();

                            NoteApi.getNote(noteUrl.restaurantUuid, noteUrl.noteNumber).then(Notedata => {
                                if (Notedata) {
                                    const amountToPay = Notedata.totalVATInc;
                                    store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL' });
                                    let note = new Note();
                                    note.importNote(Notedata);
                                    store.dispatch({ type: NOTE_CHANGE, note });

                                    let pointOfSales = new PointOfSales();
                                    pointOfSales.importPointOfSales(Notedata.store);
                                    store.dispatch({ type: POINT_OF_SALES_CHANGE, pointOfSales });

                                    let session = Notedata.session;
                                    store.dispatch({ type: SESSION_CHANGE, session });
                                    const tips = 0;
                                    store.dispatch({ type: TIPS_CHANGE, tips });
                                    let step = 0;
                                    store.dispatch({ type: STEP_CHANGE, step });
                                    store.dispatch({ type: LOADER_VISIBLE });

                                    PaymentNoteApi.create().then((response) => {
                                        OrderApi.getStripeToken(response.id).then((responseToken) => {
                                            goTo(responseToken.redirect);
                                        });
                                    });
                                }
                            }).catch(err => {
                                let step = 'ERROR';
                                store.dispatch({type: LOADER_INVISIBLE});
                                store.dispatch({type: STEP_CHANGE, step});
                            });
                        } else {
                            tokenNext(token);
                        }
                    }
                }
            }
        });
    }
}

async function cashback(boxId)
{
    const url = Environment.get().API_URL.concat('note/cashback');
    const response = await axios.get(url + '/' + boxId);
    dataStore.set('CASH_BACKPERCENT', response.data.cashbackPercent);
}

function goTo(url) {
    setTimeout(
        function() {
            document.location.href = url;
        }, 250
    );
}

function deviceOS() {
    const useragent = navigator.userAgent;

    if (useragent.match(/Android/i)) {
        return 'android';
    } else if (useragent.match(/webOS/i)) {
        return 'webos';
    } else if (useragent.match(/iPhone/i)) {
        return 'ios';
    } else if (useragent.match(/iPod/i)) {
        return 'ios';
    } else if (useragent.match(/iPad/i)) {
        return 'ios';
    } else if (useragent.match(/Windows Phone/i)) {
        return 'windows phone';
    } else if (useragent.match(/SymbianOS/i)) {
        return 'symbian';
    } else if (useragent.match(/RIM/i) || useragent.match(/BB/i)) {
        return 'blackberry';
    }

    return false;
}

async function checkIsLyf(urlobj) {
    const jwt = urlobj.searchParams.get('jwt');
    const boxId = urlobj.searchParams.get('box_id');

    if (null === jwt && null !== boxId) {
        const url = Environment.get().API_URL.concat('isLyf');
        const response = await axios.get(url + '?uuid=' + boxId);

        if (true === response.data.value) {
            dataStore.set('DESIGN', 'lyfpay');
            dataStore.set('PAYMENT_PROCESSOR', 'lyfpay');
        } else {
            dataStore.set('DESIGN', 'onepay');
            dataStore.set('PAYMENT_PROCESSOR', 'stripe');
        }
    } else {
        dataStore.set('DESIGN', 'lyfpay');
        dataStore.set('PAYMENT_PROCESSOR', 'lyfpay');
    }
}

function getUser(token) {
    UserApi.getUser({token}).catch((e) => {
        if (e) {
            console.log(e);
        }

        localStorage.clear();

        AutoLog();
    }).then((userData) => {
        let user = userData;
        store.dispatch({type: USER_GET, user});
        dataStore.set('USER', user);
    });
}

function AutoLog() {
    LoginApi.autoLogin().catch(err => {
            ErrorApi.Log({
                request: Environment.get().API_URL.concat('autologin/'),
                status: err.response.status,
                response: err.message,
                data: null
            });
        }).then((data) => {
        tokenNext(data.token);
    });
}

function JWTLog(jwt) {
    LoginApi.jwtLogin(jwt).catch(err => {
        ErrorApi.Log({
            request: LoginApi.jwtLoginRoute(),
            status: err.response.status,
            response: err.message,
            data: null
        });
    }).then((data) => {
        if (data && data.token) {
            tokenNext(data.token);
        } else {
            let step = 'ERROR';
            store.dispatch({type: LOADER_INVISIBLE});
            store.dispatch({type: STEP_CHANGE, step});
        }
    });
}

function tokenNext(token) {
    TokenManager.dispatch(token);

    getUser(token);

    // get the user
    UserManager.get(token);

    const noteUrl = new NoteUrl();

    NoteApi.getNote(noteUrl.restaurantUuid, noteUrl.noteNumber).then(Notedata => {
        if (Notedata) {
            store.dispatch({type: LOADER_INVISIBLE});

            let step = 1;
            store.dispatch({type: STEP_CHANGE, step});

            let note = new Note();
            note.importNote(Notedata);
            store.dispatch({type: NOTE_CHANGE, note});

            let pointOfSales = new PointOfSales();
            pointOfSales.importPointOfSales(Notedata.store);
            store.dispatch({type: POINT_OF_SALES_CHANGE, pointOfSales});

            let session = Notedata.session;
            store.dispatch({type: SESSION_CHANGE, session});
        }
    }).catch(err => {
        let step = 'ERROR';
        store.dispatch({type: LOADER_INVISIBLE});
        store.dispatch({type: STEP_CHANGE, step});
    });
}


// if(noteUrl.restaurantUuid && noteUrl.noteNumber){
// store.dispatch({type: NOTE_GET});
// }

let stripePromise;

if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
    stripePromise = loadStripe(Environment.get().STRIPE_KEY);
} else {
    stripePromise = null;
}

function App() {
    useEffect(() => {
        pubnub.addListener({message: handleMessage});
        pubnub.subscribe({channels});
    }, [pubnub, channels]);

    return (
        <Router>
            <React.Fragment>
                <Elements stripe={stripePromise}>
                    <ElementsConsumer>
                        {({elements, stripe}) => (
                            <PubNubProvider client={pubnub}>
                                <Loader loading={true}></Loader>
                                <NoteView></NoteView>
                                <TipView stripe={stripe}></TipView>
                                <CagnotteView stripe={stripe}></CagnotteView>
                                <CashbackView stripe={stripe}></CashbackView>
                                <RegisterView stripe={stripe}></RegisterView>
                                <LoginView stripe={stripe}></LoginView>
                                <SuccessView></SuccessView>
                                <Snack></Snack>
                                <SplitAmountView></SplitAmountView>
                                <CancelView></CancelView>
                                <SplashView></SplashView>
                                <ErrorView></ErrorView>
                            </PubNubProvider>
                        )}
                    </ElementsConsumer>
                </Elements>
            </React.Fragment>
        </Router>
    );
}

export default App;
