import { combineReducers } from "redux";
import { loaderReducer } from "../components/Loader/reducers/LoaderReducers";
import { stepReducer } from "./stepReducer";
import { noteReducer } from "./NoteReducer";
import { PointOfSalesReducer } from './PointOfSalesReducer';
import { TokenReducer } from './TokenReducer';
import { UserReducer } from './UserReducer';
import { SessionReducer } from './SessionReducer';
import { TipsReducer } from './TipsReducer';
import { ChannelReducer } from './ChannelReducer';
import { MessageReducer } from './MessageReducer';
import { AmountToPayReducer } from './AmountToPayReducer';
import { ErrorReducer } from './ErrorReducer';

export default combineReducers({
    loader: loaderReducer,
    steps: stepReducer,
    note: noteReducer,
    pointOfSales: PointOfSalesReducer,
    token: TokenReducer,
    user: UserReducer,
    session: SessionReducer,
    tips: TipsReducer,
    channel: ChannelReducer,
    message: MessageReducer,
    amountToPay: AmountToPayReducer,
    error: ErrorReducer
});
