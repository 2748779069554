export class Note{

    currency;
    productNotes;
    roomRef;
    status;
    submitedAt;
    tableRef;
    totalVATExc;
    totalVATInc;
    vat;
    waiter;

    constructor() {
        this.currency = null;
        this.productNotes = [];
        this.roomRef = null;
        this.status = null;
        this.submitedAt = null;
        this.tableRef = null;
        this.totalVATExc = null;
        this.totalVATInc = null;
        this.vat = null;
        this.waiter = null;
    }

    importNote(data) {
        this.currency = data.currency;
        data.productNotes.forEach(product => {
            this.productNotes.push(product);
        });
        this.roomRef = data.roomRef;
        this.status = data.status;
        this.submitedAt = data.submitedAt;
        this.tableRef = data.tableRef;
        this.totalVATExc = data.totalVATExc;
        this.totalVATInc = data.totalVATInc;
        this.vat = data.vat;
        this.waiter = data.waiter;
    }
}

