import { ERROR } from '../actions/ErrorActions';

const InitialState = {
    error: null
};

export function ErrorReducer(state = InitialState, action) {
    if (action.type === ERROR) {
        return {
            ...state,
            error: action.error
        }
    }

    return state;
}