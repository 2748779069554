import React from "react";
import store from '../../Store';

export class TotalHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total: 0,
            total2: 0,
            isLocked: false
        };

        store.subscribe(() => {
            let previousValue = this.state.total;
            let currentNoteTotal = store.getState().note.note.totalVATInc;

            if (store.getState().session.session) {
                this.setState({ isLocked: store.getState().session.session.isLocked });
            }

            if (!previousValue || previousValue.noteTotal !== currentNoteTotal) {
                let paidAmount = 0;
                if (store.getState().session.session) {
                    paidAmount = store.getState().session.session.paidAmount;
                }

                if (currentNoteTotal && paidAmount) {
                    this.setState({
                        total: (currentNoteTotal - paidAmount).toFixed(2)
                    });
                } else if (currentNoteTotal) {
                    this.setState({
                        total: (currentNoteTotal).toFixed(2)
                    });
                }
            }

            if (currentNoteTotal) {
                let total2 = (currentNoteTotal).toFixed(2);
                this.setState({
                    total2: total2
                });
            }

            if (store.getState().session.session) {
                this.setState({ isLocked: store.getState().session.session.isLocked });
            }
        });
    }

    render() {
        return(
            <div className={this.state.isLocked ? 'total-header green' : this.state.total === this.state.total2 ? 'total-header' : 'total-header orange'}>
                <h1>Payer l'addition</h1>
                <p>Montant total : {this.state.total2} €</p>
                <p>Montant dû</p>
                <div className="total-header-total">{this.state.total} €</div>
                
            </div>
        )
    }
}
