import React from 'react';
import store from '../../Store';
import {LogoOnePay} from './LogoOnePay';


export class Loader extends React.Component {
    state = {};
    isActive = true;

    constructor(props){
        super(props);

        this.state = {
            isActive: props.loading
        };

        store.subscribe(() => {
            this.setState({ isActive: store.getState().loader.isActive});
        });
        
    }

    render(){
        return (
            <div className={`loader-container ${store.getState().loader.isActive ? '' : 'none'}`}>
                <React.Fragment>
                    <LogoOnePay></LogoOnePay>
                </React.Fragment>
                <div className="loader" ></div>
            </div>
        );
    }

    componentDidMount(){
        store.subscribe(() => {
            let previousValue = this.state.isActive;
            let currentValue = store.getState().loader.isActive;
    
            if (previousValue !== currentValue) {
                this.setState(store.getState().loader);
            }
        });
    }
}
