import { POINT_OF_SALES_CHANGE } from '../actions/PointOfSalesActions';
import { PointOfSales } from '../app/PointOfSales';

const InitialState = {
    pointOfSales: new PointOfSales()
};

export function PointOfSalesReducer(state = InitialState, action) {
    if (action.type === POINT_OF_SALES_CHANGE) {
        return {
            ...state,
            pointOfSales: action.pointOfSales
        };
    } 
    return state;
}