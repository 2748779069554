import React from "react";
import { STEP_CHANGE } from '../actions/stepActions';
import { TOKEN_GET } from '../actions/TokenActions';
import { USER_FIRSTNAME, USER_GET, USER_IS_ACKED, USER_LASTNAME, USER_PASSWORD, USER_PHONE } from '../actions/UserActions';
import { UserApi } from '../api/UserApi';
import { User } from '../app/User';
import store from '../Store';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { PaymentNoteApi } from '../api/PaymentNoteApi';
import { OrderApi } from '../api/OrderApi';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { LOADER_INVISIBLE, LOADER_VISIBLE } from '../components/Loader/actions/actions';
import { NoteUrl } from '../app/NoteUrl/NoteUrl';
import { ERROR } from '../actions/ErrorActions';
import { Environment } from '../environments/environment';
import {LocalStorageManager} from "../services/LocalStorageManager";


export class RegisterView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstname: '',
            firstnameError: false,
            lastname: '',
            lastnameError: false,
            password: '',
            passwordError: true,
            stripe: null,
            isAcked: false,
            phone: '',
            error: false
        };

        store.subscribe(() => {
            this.setState({
                visible: store.getState().steps.step === 4,
            });

            if (store.getState().user.user) {
                this.setState({
                    firstname: store.getState().user.user.firstname,
                    lastname: store.getState().user.user.lastname,
                    phone: store.getState().user.user.phone,
                    password: store.getState().user.user.password,
                    isAcked: store.getState().user.user.isAcked !== "" ? store.getState().user.user.isAcked : false
                });
            }
        });
    }

    updateFirstname(event) {
        if (event.target.value && event.target.value.length > 0) {
            this.setState({ firstnameError: false });
            this.setState({ firstname: event.target.value });
            let user = store.getState().user.user;
            store.dispatch({ type: USER_FIRSTNAME, firstname: event.target.value , user });
        } else {
            this.setState({ firstnameError: true });
        }
        
    }

    updateLastname(event) {
        if (event.target.value && event.target.value.length > 0) {
            this.setState({ lastnameError: false });
            this.setState({ lastname: event.target.value });
            let user = store.getState().user.user;
            store.dispatch({ type: USER_LASTNAME, lastname: event.target.value, user });
        } else {
            this.setState({ lastnameError: true });
        }
    }

    updatePassword(event) {
        const re = /(?=.*[A-Za-z@$!%*?&,.])(?=.*\d)[A-Za-z\d@$!%*?&,.]{8,}/g
        let t = re.test(String(event.target.value));

        if (t) {
            this.setState({ passwordError: false });
        } else {
            this.setState({ passwordError: true });
        }

        this.setState({ password: event.target.value });
        let user = store.getState().user.user;

        store.dispatch({ type: USER_PASSWORD, password: event.target.value, user });
        
    }

    updatePhone(event) {
        this.setState({ phone: event.target.value });
        let user = store.getState().user.user;
        store.dispatch({ type: USER_PHONE, phone: event.target.value, user });
    }

    handleChange(event) {
        console.log(this.state.isAcked);

        if (!this.state.isAcked || this.state.isAcked === ""){
            this.setState({ isAcked: true });
            let user = store.getState().user.user;
            store.dispatch({ type: USER_IS_ACKED, isAcked: true, user });
        } else {
            this.setState({ isAcked: false });
            let user = store.getState().user.user;
            store.dispatch({ type: USER_IS_ACKED, isAcked: false, user });
        }
    }

    handleRegister() {
        const jwt = LocalStorageManager.getJWT();
        const re = /(?=.*[A-Za-z@$!%*?&,.])(?=.*\d)[A-Za-z\d@$!%*?&,.]{8,}/g

        if (!this.state.firstnameError && this.state.firstname.length > 0
            && !this.state.lastnameError && this.state.lastname.length > 0
            && !this.state.passwordError && re.test(this.state.password)
        ) {
            let step = 0;
            store.dispatch({ type: STEP_CHANGE, step });
            store.dispatch({ type: LOADER_VISIBLE });
            const stripe = this.props.stripe;

            let email = store.getState().user.user.email;
            let firstname = store.getState().user.user.firstname;
            let lastname = store.getState().user.user.lastname;
            let password = store.getState().user.user.password;
            let isAcked = store.getState().user.user.isAcked;
            let phone = store.getState().user.user.phone;
            let n = new NoteUrl();
            let onebox = n.restaurantUuid;

            UserApi.create({
                email, firstname, lastname, password, isAcked, phone, onebox
            }).catch(err => {
                this.setState({ error: true });
                store.dispatch({ type: LOADER_INVISIBLE });
                let step = 3;
                store.dispatch({ type: STEP_CHANGE, step });
                let error = true;
                store.dispatch({ type: ERROR, error });
            }).then((responseRegister) => {
                if(!responseRegister.token){
                    this.setState({ error: true });
                    store.dispatch({ type: LOADER_INVISIBLE });
                    let step = 3;
                    store.dispatch({ type: STEP_CHANGE, step });
                    let error = true;
                    store.dispatch({ type: ERROR, error });
                } else {
                    const dataStore = new LocalStorageManager('app');
                    let token = responseRegister.token;
                    store.dispatch({ type: TOKEN_GET, token });

                    const userMemory = dataStore.get('USER', JSON.parse('{}'));
                    let user = new User();
                    user.importUser(store.getState().user.user);
                    user.email = email;
                    user.firstname = firstname;
                    user.lastname = lastname;
                    user.password = password;
                    store.dispatch({ type: USER_GET, user });

                    let cashbackable = false;

                    const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

                    const val = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);

                    if (userMemory.aeoUserId !== undefined && userMemory.cashbacks !== undefined) {
                        const valCashback = parseFloat('' + userMemory.cashbacks[0].amount);
                        cashbackable = user.aeoUserId !== null && val > 0 && valCashback > 0;
                    }

                    let step = 0;

                    if (!cashbackable) {
                        step = 9;
                        store.dispatch({type: STEP_CHANGE, step});

                        PaymentNoteApi.create().then((response) => {
                            OrderApi.getStripeToken(response.id).then((responseToken) => {
                                if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                                    stripe.redirectToCheckout({sessionId: responseToken}).then((log) => {
                                        console.log(log);
                                    });
                                } else {
                                    if (null === jwt) {
                                        window.location.href = responseToken.redirect;
                                    } else {
                                        window.location.replace(responseToken.redirect);
                                    }
                                }
                            });
                        });
                    } else {
                        step = 'CASHBACK';
                        store.dispatch({ type: STEP_CHANGE, step });
                    }
                }
            }).catch(err => {
                this.setState({ error: true });
                store.dispatch({ type: LOADER_INVISIBLE });
                let step = 3;
                store.dispatch({ type: STEP_CHANGE, step });
                let error = true;
                store.dispatch({ type: ERROR, error });

            });
        }
    }

    handleAccount() {
        let step = 5;
        store.dispatch({ type: STEP_CHANGE, step });
    }

    render() {
        let logo;
        if (Environment.get().DESIGN === 'onepay') {
            logo = 'logo.svg';
        } else {
            logo = 'logo.png';
        }

        return (
            <div className={this.state.visible ? '' : 'none'}>
                <div className="center cagnotte-img">
                    <img src={process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/' + logo} className="loader-logo" alt="logo" />
                </div>
                <div className="cagnotte center">
                    <strong>Inscrivez vous</strong>, cela ne vous prendra que quelques secondes
                </div>
                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Pr&eacute;nom"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre pr&eacute;nom"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            name: 'firstname',
                            autoComplete: 'given-name'
                        }}
                        value={this.state.firstname}
                        onChange={this.updateFirstname.bind(this)}
                    />
                </div>
                <div className={this.state.firstnameError ? 'error-msg' : 'error-msg none'}>Veuillez entrer un pr�nom</div>
                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Nom"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre nom"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps= {{
                            name: 'lastname',
                            autoComplete: 'family-name'
                        }}
                        value={this.state.lastname}
                        onChange={this.updateLastname.bind(this)}
                    />
                </div>
                <div className={this.state.lastnameError ? 'error-msg' : 'error-msg none'}>Veuillez entrer un nom</div>
                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Mot de passe"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre mot de passe"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'new-password'
                        }}
                        value={this.state.password}
                        onChange={this.updatePassword.bind(this)}
                        type="password"
                    />
                    <div className={this.state.passwordError ? 'error-msg' : 'error-msg none'}>Veuillez entrer un mot de passe comportant au moins 8 caracteres dont un chiffre</div>
                </div>
                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Num&eacute;ro de t&eacute;l&eacute;phone"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre num&eacute;ro de t&eacute;l&eacute;phone"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'tel'
                        }}
                        value={this.state.phone}
                        onChange={this.updatePhone.bind(this)}
                        type="text"
                    />
                </div>
                <div className="container">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.isAcked}
                                onChange={this.handleChange.bind(this)}
                                name="isAcked"
                                color="primary"
                            />
                        }
                        label="J'accepte de recevoir des &eacute;v&egrave;nements et bons plans de la part du restaurant"
                    />
                </div>
                <div className="button-container">
                    <Button onClick={this.handleRegister.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Créer mon compte
                    </Button>
                </div>
                <div className="center link" onClick={this.handleAccount.bind(this)}>J'ai d&eacute;ja un compte, me connecter</div>
                </div>
            );
    }
}
