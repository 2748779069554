import { LoginApi } from '../api/LoginApi';
import React from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import store from '../Store';
import { STEP_CHANGE } from '../actions/stepActions';
import { LOADER_INVISIBLE, LOADER_VISIBLE } from '../components/Loader/actions/actions';
import { TOKEN_GET } from '../actions/TokenActions';
import { PaymentNoteApi } from '../api/PaymentNoteApi';
import { OrderApi } from '../api/OrderApi';
import { UserManager } from '../services/UserManager';
import { Environment } from '../environments/environment';
import { LocalStorageManager } from "../services/LocalStorageManager";
import {UserApi} from "../api/UserApi";
import {USER_GET} from "../actions/UserActions";

export class LoginView extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            stripe: null,
            error: false,
            loginError: false,
            returnError: false
        };

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 5 });
        });
    }

    updateEmail (event) {
        this.setState({ email: event.target.value });
    }

    updatePassword  (event) {
        this.setState({ password: event.target.value });
    }

    getUser(token, cb) {
        const dataStore = new LocalStorageManager('app');

        UserApi.getUser({token}).catch((e) => {
            if (e) {
                console.log(e);
            }
        }).then((userData) => {
            let user = userData;
            store.dispatch({type: USER_GET, user});
            dataStore.set('USER', user);
            cb(user);
        });
    }

    handleRegister () {
        let email = this.state.email;
        let password = this.state.password;
        const jwt = LocalStorageManager.getJWT();

        if (email.length > 0 && password.length > 0) {
            this.setState({ error: false });
                LoginApi.loginCheck(email, password)
                    .then(response => {
                        this.setState({ returnError: false });

                        if (!this.state.loginError) {
                            this.getUser(response.token, function (user) {
                                const dataStore = new LocalStorageManager('app');
                                let cashbackable = false;

                                const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

                                const val = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);

                                if (user.aeoUserId !== undefined && user.cashbacks !== undefined) {
                                    const valCashback = parseFloat('' + user.cashbacks[0].amount);
                                    cashbackable = user.aeoUserId !== null && val > 0 && valCashback > 0;
                                }

                                let step = 0;

                                if (!cashbackable) {
                                    store.dispatch({ type: STEP_CHANGE, step });
                                    store.dispatch({ type: LOADER_VISIBLE });

                                    const stripe = this.props.stripe;

                                    let token = response.token;
                                    store.dispatch({ type: TOKEN_GET, token });
                                    UserManager.get(token);

                                    step = 9;
                                    store.dispatch({ type: STEP_CHANGE, step });

                                    PaymentNoteApi.create().then((response) => {
                                        OrderApi.getStripeToken(response.id).then((responseToken) => {
                                            if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                                                stripe.redirectToCheckout({ sessionId: responseToken }).then((log) => {
                                                    console.log(log);
                                                });
                                            } else {
                                                if (null === jwt) {
                                                    window.location.href = responseToken.redirect;
                                                } else {
                                                    window.location.replace(responseToken.redirect);
                                                }
                                            }
                                        });
                                    });
                                } else {
                                    step = 'CASHBACK';
                                    store.dispatch({ type: STEP_CHANGE, step });
                                }
                            });
                        }
                    }).catch(err => {
                        this.setState({ returnError: true });
                        store.dispatch({ type: LOADER_INVISIBLE });
                        let step = 5;
                        store.dispatch({ type: STEP_CHANGE, step });
                    });
 
        } else {
            this.setState({ error: true });
        }
    }

    handleNoAccount () {
        const jwt = LocalStorageManager.getJWT();
        let step = 0;
        store.dispatch({ type: STEP_CHANGE, step });
        store.dispatch({ type: LOADER_VISIBLE });
        const stripe = this.props.stripe;

        PaymentNoteApi.create().then((response) => {
            OrderApi.getStripeToken(response.id).then((responseToken) => {
                if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                    stripe.redirectToCheckout({ sessionId: responseToken }).then((log) => {
                        console.log(log);
                    });
                } else {
                    if (null === jwt) {
                        window.location.href = responseToken.redirect;
                    } else {
                        window.location.replace(responseToken.redirect);
                    }
                }
            });
        });
    }

    handleAccount () {
        let step = 3;
        store.dispatch({ type: STEP_CHANGE, step });
    }

    render () {
        let txt = "";

        if (store.getState().store && store.getState().store.store && store.getState().store.store.storeFeatures) {
            store.getState().store.store.storeFeatures.map((feat, index) => {
                if (feat && feat.feature && feat.feature.key === 'cashback' && feat.feature.isGranted && feat.feature.value) {
                    txt = ' et cagnottez '.concat(((Number(store.getState().amountToPay.amountToPay)) / 10).toFixed(2)).concat('�');
                }
            });
        }

        let logo;

        if(store.getState().pointOfSales.pointOfSales.logo){
            logo = Environment.get().API_BASE + store.getState().pointOfSales.pointOfSales.logo;
        } else if (process.env.REACT_APP_ENVIRONMENT === 'lyfpay') {
            logo = process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/logo.png';
        } else {
            logo = process.env.PUBLIC_URL + '/profil/' + process.env.REACT_APP_ENVIRONMENT + '/logo.svg';
        }

        return (
            <div className={this.state.visible ? '' : 'none'}>
                <div className="center cagnotte-img">
                    <img src={logo} className="loader-logo" alt="Logo" />
                </div>

                <div className="cagnotte center">
                    <strong>Identifiez-vous{txt}</strong>, cela ne vous prendra que quelques secondes
                </div>

                <div className="container">
                    <TextField
                        id="standard-full-width"classCss
                        label="Email"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre Email"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'username'
                        }}
                        value={this.state.email}
                        onChange={this.updateEmail.bind(this)}
                    />
                </div>

                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Mot de passe"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre mot de passe"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'current-password'
                        }}
                        value={this.state.password}
                        onChange={this.updatePassword.bind(this)}
                        type="password"
                    />
                </div>

                <div className={this.state.error ? 'error-msg' : 'error-msg none'}>Veuillez compléter les champs ci-dessus</div>

                <div className={this.state.loginError ? 'error-msg' : 'error-msg none'}>L'email / mot de passe ne correspondent pas</div>

                <div className={this.state.returnError ? 'error-msg' : 'error-msg none'}>Vos identifiants sont invalide</div>

                <div className="button-container">
                    <Button onClick={this.handleRegister.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Valider
                    </Button>
                </div>

                <div className="button-container b-sec">
                    <Button onClick={this.handleAccount.bind(this)} color="primary" variant="outlined" className="dialog-button">
                        Cr&eacute;er un compte
                    </Button>
                </div>

                <div className="center link" onClick={this.handleNoAccount.bind(this)}>Continuer sans compte</div>

            </div>
        );
    }
}
