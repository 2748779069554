import { CHANNEL } from '../actions/ChannelActions';

const InitialState = {
    channel: null
};

export function ChannelReducer(state = InitialState, action) {
    if (action.type === CHANNEL) {
        return {
            ...state,
            channel: action.channel
        }
    }

    return state;
}