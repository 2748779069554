import React from "react";
import store from '../Store';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { USER_EMAIL } from '../actions/UserActions';
import { STEP_CHANGE } from '../actions/stepActions';
import { LOADER_VISIBLE } from '../components/Loader/actions/actions';
import { PaymentNoteApi } from '../api/PaymentNoteApi';
import { OrderApi } from '../api/OrderApi';
import { Environment } from '../environments/environment';
import {LocalStorageManager} from "../services/LocalStorageManager";

export class CagnotteView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: store.getState().steps.step === 3,
            email: '',
            emailError: true,
            error: false
        };

        store.subscribe(() => {
            this.setState({ visible: store.getState().steps.step === 3 });
            this.setState({ error: store.getState().error.error });
        });
    }

    render() {
        const dataStore = new LocalStorageManager('app');
        const percent = parseFloat('' + dataStore.get('CASH_BACKPERCENT', 0));

        const val = ((Number(store.getState().amountToPay.amountToPay)) * percent / 100).toFixed(2);

        return (
            <div className={this.state.visible ? '' : 'none'}>
                <div className="center cagnotte-img">
                    <img src={process.env.PUBLIC_URL + '/wallet.png'} className="loader-logo2" alt="Cagnotte personnelle" />
                </div>

                <div className="cagnotte center">
                    <strong>Cagnottez { val } €</strong> en créant votre compte, cela ne vous prendra que quelques secondes
                </div>

                <div className="container">
                    <TextField
                        id="standard-full-width"
                        label="Email"
                        style={{ margin: 8 }}
                        placeholder="Entrez votre adresse email"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete:'username'
                        }}
                        value={this.state.email}
                        onChange={this.updateEmail.bind(this)}
                    />
                </div>
                <div className={this.state.emailError ? 'error-msg' : 'error-msg none'}>Veuillez entrer un email valide</div>
                <div className={this.state.error ? 'error-msg' : 'error-msg none'}>Cet email est déjà enregistré</div>
                    <div className="button-container">
                        <Button onClick={this.handleEmail.bind(this)} color="primary" variant="outlined" className="dialog-button">
                                Continuer
                        </Button>
                    </div>
                    <div className="button-container b-sec">
                        <Button onClick={this.handleAccount.bind(this)} color="primary" variant="outlined" className="dialog-button">
                            J'ai déjà un compte, me connecter
                        </Button>
                    </div>
                <div className="center link" onClick={this.handleNoAccount.bind(this)}>Continuer sans compte</div>
            </div>
            );
    }

    handleEmail() {
        if (!this.state.emailError) {
            let email = this.state.email;
            let user = store.getState().user.user;
            store.dispatch({ type: USER_EMAIL, email, user });

            let step = 4;
            store.dispatch({ type: STEP_CHANGE, step });
        }
        
    }

    handleNoAccount() {
        const jwt = LocalStorageManager.getJWT();
        let step = 0;
        store.dispatch({ type: STEP_CHANGE, step });
        store.dispatch({ type: LOADER_VISIBLE });
        const stripe = this.props.stripe;

        PaymentNoteApi.create().then((response) => {
            OrderApi.getStripeToken(response.id).then((responseToken) => {
                if (Environment.get().PAYMENT_PROCESSOR === 'stripe') {
                    stripe.redirectToCheckout({ sessionId: responseToken }).then((log) => {
                        console.log(log);
                    });
                } else {
                    if (null === jwt) {
                        window.location.href = responseToken.redirect;
                    } else {
                        window.location.replace(responseToken.redirect);
                    }
                }
            })
        })
    }

    handleAccount() {
        let step = 5;
        store.dispatch({ type: STEP_CHANGE, step });
    }

    updateEmail(event) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let t = re.test(String(event.target.value).toLowerCase());
        if (t) {
            this.setState({ email: event.target.value });
            this.setState({ emailError: false });
        } else {
            this.setState({ email: event.target.value });
            this.setState({ emailError: true });
        }
        
    }
}
