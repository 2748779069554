import axios from 'axios';
import { Environment } from '../environments/environment';
import store from '../Store';
import { ErrorApi } from './ErrorApi';

export class UserApi {
    static async pubnub(arg) {
        let token = arg.token;
        let auth = "Bearer ".concat(token);
        let session = store.getState().session.session.id;

        try {
            const response = await axios.get(
                Environment.get().API_URL.concat('note/session/').concat(session).concat('/push'), {
                    headers: {
                        'Authorization': auth
                    }
                }
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: UserApi.userGetRoute(),
                status: err.response.status,
                response: err.message,
                data: { token: arg.token },
                method: 'GET'
            });
            return err;
        }
    }

    static async getUser(arg) {
        let token = arg.token;
        let auth = "Bearer ".concat(token);

        const response = await axios.get(
            UserApi.userGetRoute(), {
                headers: {
                    'Authorization': auth
                }
            }
        );

        if (response) {
            return response.data;
        }
    }

    static async update(arg) {
        let email = arg.email;
        let firstName = arg.firstname;
        let lastName = arg.lastname;
        let password = arg.password;
        let phone = arg.phone;
        let isAcked = arg.isAcked;

         try {
             const response = await axios.patch(
                 UserApi.userUpdateRoute(arg.onebox), {
                    firstName,
                    lastName,
                    email,
                    password,
                    phone,
                    isAcked
                }, {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            ); 

            if (response) {
                return response.data;
            }
        } catch (err) {
             ErrorApi.Log({
                 method: 'PATCH',
                 request: UserApi.userUpdateRoute(),
                 status: err.response.status,
                 response: err.message,
                 data: arg
             });

             return err;
        }
        
    }

    static async create(arg) {
        let email = arg.email;
        let firstName = arg.firstname;
        let lastName = arg.lastname;
        let password = arg.password;
        let isAcked = arg.isAcked;
        let phone = arg.phone;

        try {
            const response = await axios.put(
                UserApi.userUpdateRoute(arg.onebox), {
                firstName,
                lastName,
                email,
                password,
                phone,
                isAcked
            },
                {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                   // validateStatus: () => false
                }
            ).then((response) => {
                console.log(response);
                return response;
            }).catch((err) => {
                console.log(err);
            });

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                method: 'PATCH',
                request: UserApi.userUpdateRoute(),
                status: err.response.status,
                response: err.message,
                data: arg
            });

            return err;
        }
    }

    static userGetRoute() {
        const href = window.location.href;
        const url = new URL(href);
        let boxId = url.searchParams.get('box_id');

        if (null === boxId) {
            const partsHref = href.split('/');
            boxId = partsHref[partsHref.length - 2];
        }

        if (null === boxId) {
            return Environment.get().API_URL.concat('user/me');
        }

        return Environment.get().API_URL.concat('user/me/' + boxId);
    }

    static userUpdateRoute(arg) {
        return Environment.get().API_URL.concat('user/').concat(arg);
    }
}
