import { SESSION_CHANGE } from '../actions/SessionActions';

const InitialState = {
    session: null
};

export function SessionReducer(state = InitialState, action) {
    

    if (action.type === SESSION_CHANGE) {

        if (!InitialState.session) {
            InitialState.session = action.session;
        }

        let t = {
            ...state,
            session: action.session
        }

        return t
    }

    return state;
}