import { Environment } from '../environments/environment';
import store from '../Store';
const axios = require('axios').default;

export class ErrorApi {

    static async Log(args) {
        let data = JSON.stringify( args.data );
        let device = navigator.userAgent;
        let request = args.request;
        let status = args.status;
        let response = args.response;
        let note = store.getState().note.note.id || null;
        let method = args.method;

        try {
            const resp = await axios.post(
                ErrorApi.getRoute(),
                {
                    device,
                    request,
                    status,
                    response,
                    note,
                    data,
                    method
                },
                {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            );
            if (resp) {
                return resp.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    static getRoute() {
        return Environment.get().API_URL
            .concat('log')
    }
}