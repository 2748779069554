import { data } from './DataService';

export class LocalStorageManager {
    constructor(context = 'core') {
        this.context = context;
    }

    getKey(key) {
        return this.context + '.' + key;
    }

    del(key) {
        const status = this.has(key);

        if (status) {
            delete data[this.getKey(key)];
        }

        return status;
    }

    has(key) {
        return typeof data[this.getKey(key)] !== 'undefined';
    }

    set(key, value) {
        data[this.getKey(key)] = value;

        return this;
    }

    get(key, def = null) {
        if (this.has(key)) {
            return data[this.getKey(key)];
        }

        return def;
    }

    static record(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch(error) {
            console.log(error);
        }
    }

    static read(key) {
        let result;

        try {
            result = JSON.parse(localStorage.getItem(key));
        } catch(error) {
            console.log(error);
            result = false;
        }

        return result;
    }

    static getJWT() {
        const href = window.location.href;
        const url = new URL(href);

        return url.searchParams.get('jwt');
    }

    static getNoteId() {
        const href = window.location.href;
        const url = new URL(href);

        return url.searchParams.get('note_id');
    }

    static getBoxId() {
        const href = window.location.href;
        const url = new URL(href);

        return url.searchParams.get('box_id');
    }
}
