import React from "react";
import store from '../../Store';

export class TipsHeader extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            waiter: store.getState().note.note.waiter
        };

        store.subscribe(() => {
            this.setState({ waiter: store.getState().note.note.waiter });
        });
    }

    render() {
        let txt;

        if (store.getState().note.note && store.getState().note.note.waiter) {
            txt = 'Laisser un pourboire à :';
        } else {
            txt = 'Laisser un pourboire';
        }

        return(
            <div className="total-header tips-header">
                <h1>{ txt }<br />
                    <strong>
                        { store.getState().note.note && store.getState().note.note.waiter ? this.state.waiter : '' }
                    </strong>
                </h1>
                <p>
                    Les pourboires sont appréciés<br />
                    mais restent facultatifs
                </p>
            </div>
        )
    }

    componentDidMount(){
        store.subscribe(() => {
            let previousValue = this.state.total;
            let currentNoteTotal = store.getState().note.note.totalVATInc;
    
            if (!previousValue || previousValue.noteTotal !== currentNoteTotal) {
                this.setState({
                    total: currentNoteTotal
                });
              }
        });
    }
}
