import { USER_EMAIL, USER_FIRSTNAME, USER_GET, USER_IS_ACKED, USER_LASTNAME, USER_PASSWORD, USER_PHONE } from '../actions/UserActions';
import { User } from '../app/User';
import { LocalStorageManager } from '../services/LocalStorageManager';

const InitialState = {
    user: new User()
};

export function UserReducer(state = InitialState, action) {
    if (action.type === USER_GET) {
        let user = new User();

        user.importUser(action.user);
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_EMAIL) {
        let user = new User();

        user.importUser(action.user);
        user.email = action.email;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_FIRSTNAME) {
        let user = new User();

        user.importUser(action.user);
        user.firstname = action.firstname;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_LASTNAME) {
        let user = new User();

        user.importUser(action.user);
        user.lastname = action.lastname;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_IS_ACKED) {
        let user = new User();

        user.importUser(action.user);
        user.isAcked = action.isAcked;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_PASSWORD) {
        let user = new User();

        user.importUser(action.user);
        user.password = action.password;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    if (action.type === USER_PHONE){
        let user = new User();

        user.importUser(action.user);
        user.phone = action.phone;
        LocalStorageManager.record('user', user);

        return {
            ...state,
            user
        };
    }

    return state;
}
