import {LocalStorageManager} from "../../services/LocalStorageManager";

export class NoteUrl {
    /**
     * @param String
     */
    fullUrl;
    /**
     * @param String
     */
    path;
    /**
     * @param String
     */
    restaurantUuid;
    /**
     * @param String
     */
    noteNumber;

    constructor() {
        this.fullUrl = window.location.href;
        this.path = window.location.pathname;
        this.restaurantUuid = null;
        this.NoteNumber = null;

        if (this.isANoteUrl()) {
            this.restaurantUuid = this.getRestaurantUuidFromPath();
            this.noteNumber = this.getNoteNumberFromPath();
        }
    }

    /**
     * @method isANoteUrl
     * @description check if the url include /note/ and return true if so
     * @returns boolean
     */
    isANoteUrl() {
        return !!this.path.includes('/note');
    }

    /**
     * @method getRestaurantUuidFromPath
     * @description extract the restaurant uuid from path
     * @returns string
     */
    getRestaurantUuidFromPath() {
        const bid = LocalStorageManager.getBoxId();

        if (bid !== null) {
            return bid;
        }

        const href = window.location.href;
        const url = new URL(href);

        const boxId = url.searchParams.get('box_id');

        if (boxId === null) {
            return this.path.replace('/note/', '').split('/')[0];
        }

        return boxId;
    }

    /**
     * @method getNoteNumberFromPath
     * @description extract the note number from path
     * @returns number
     */
    getNoteNumberFromPath() {
        const nid = LocalStorageManager.getNoteId();

        if (nid !== null) {
            if (!nid.match('-')) {
                return Number(nid);
            }

            return nid;
        }

        const href = window.location.href;
        const url = new URL(href);

        let noteId = url.searchParams.get('note_id');

        if (null === noteId) {
            const partsHref = href.split('/');
            noteId = partsHref.pop();
        }

        if (!noteId.match('-')) {
            return Number(noteId);
        }

        return noteId;
    }
}
