import { Environment } from '../environments/environment';
import store from '../Store';
import { ErrorApi } from './ErrorApi';
import {LocalStorageManager} from "../services/LocalStorageManager";
const axios = require('axios').default;

export class PaymentNoteApi {

    static async create() {
        const tip = store.getState().tips.tips;
        const amount = parseFloat('' + store.getState().amountToPay.amountToPay);
        const jwt = LocalStorageManager.getJWT();
        const dataStore = new LocalStorageManager('app');
        const cashbackAmount = dataStore.get('CASHBACK_AMOUNT', 0);
        const choosenproducts = dataStore.get('CHOOSEN_PRODUCTS', []).join(',');

        try {
            const response = await axios.post(
                PaymentNoteApi.paymentNoteCreateRoute(),
                {
                    tip,
                    amount,
                    jwt,
                    cashbackAmount,
                    choosenproducts,
                },
                {
                    headers: {
                        'Authorization': "Bearer ".concat(store.getState().token.token)
                    }
                }
            );

            if (response) {
                return response.data;
            }
        } catch (err) {
            ErrorApi.Log({
                request: PaymentNoteApi.paymentNoteCreateRoute(),
                status: err.response.status,
                response: err.message,
                data: null,
                method: 'POST'
            });
        }
    }

    static paymentNoteCreateRoute() {
        return Environment.get().API_URL
            .concat('session/')
            .concat(store.getState().session.session.id)
            .concat('/payment_note');
    }
}
